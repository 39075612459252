import { AutoRenewIcon, Button, Flex, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import ConnectWalletButton from "components/ConnectWalletButton";
import { BONUS, DEFAULT_SLIPPAGE } from "config";
import { useVNDCContract } from "hooks/useContract";
import useTheme from "hooks/useTheme";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { GetDataActions, UseActions } from "state/action";
import { fetchActionActiveTab } from "state/action/actions";
import { ACTION_ACTIVETAB } from "state/action/types";
import { formatNumber } from "utils/formatNumber";
import { useActions } from "../hook/useActions";
import { getTokenUSDC, getTokenVNDC } from "../ultils/getToken";
import InputBalance from "./InputBalance";
import { ContainerAction, CsButtonSecondary } from "./styles";
import { getAmountInFromLP, getAmountOutFromLP } from "../ultils/getAmounts";

interface Props {
    account:string,
    chainId:number
}
const Actions:React.FC<Props> = ({
    account,
    chainId
}) => {
    const dataActions = GetDataActions()
    const { activeTab, dataCurrent, txHash, dataMint } = dataActions
    const dispatch = useDispatch<AppDispatch>();
    const { theme } = useTheme()
    const [ amountInput, setAmountInput ] = useState("")
    const [ amountOutput, setAmountOutput ] = useState("")
    const [isChangeOutput, setIsChangeOutput] = useState(false)
    const tokenVNDC = getTokenVNDC(chainId)
    const tokenUSDC = getTokenUSDC(chainId)
    const currentItem = useMemo(() => {
        return {
            currentInput: activeTab === ACTION_ACTIVETAB.MINT ? tokenUSDC  : tokenVNDC,
            currentOutput: activeTab === ACTION_ACTIVETAB.MINT ? tokenVNDC : tokenUSDC,
        }
    },[activeTab, tokenUSDC, tokenVNDC])
    UseActions(currentItem, account, chainId, txHash)

    const handleChangeTab = (newTab: number) => {
        dispatch(fetchActionActiveTab({activeTab: newTab}))
        setAmountInput("")
        setAmountOutput("")
    }

    const renderFee = useMemo(() => {
        if(activeTab === ACTION_ACTIVETAB.REDEEM){
            return new BigNumber(amountOutput).multipliedBy(dataMint?.feeWithdraw).decimalPlaces(6,1).toString()
        }
        return "0"
    },[activeTab, amountOutput, dataMint?.feeWithdraw])

    const renderBonus = useMemo(() => {
        if(activeTab === ACTION_ACTIVETAB.MINT){
            return (new BigNumber(amountOutput).multipliedBy(BONUS)).decimalPlaces(6,1).toString()
        }
        return "0"
    },[activeTab, amountOutput])


    const renderVndcPerUsdc = useMemo(() => {
        return new BigNumber(dataMint?.reserve1).dividedBy(dataMint?.reserve0).toNumber()
    },[dataMint?.reserve0, dataMint?.reserve1])
    
    const handleChangeInput = (newValue) => {
        setAmountInput(newValue)
        if(activeTab === ACTION_ACTIVETAB.MINT){
            const amountB = new BigNumber(newValue).dividedBy(renderVndcPerUsdc)
            setAmountOutput(new BigNumber(amountB)?.toString())
        } else {
            const amountB = getAmountOutFromLP(newValue, dataMint?.lpPerUser, dataMint?.totalVndcCanRedeem, dataMint?.totalLp, dataMint?.reserve1)
            setAmountOutput(new BigNumber(amountB).toString())
        }
    }

    const handleChangeOutput = (newValue) => {
        setAmountOutput(newValue)
        if(isChangeOutput === false){
            if(activeTab === ACTION_ACTIVETAB.MINT){
                const amountA = new BigNumber(newValue).multipliedBy(renderVndcPerUsdc)
                setAmountInput(new BigNumber(amountA)?.toString())
            } else {
                const amountA = getAmountInFromLP(newValue, dataMint?.totalLp, dataMint?.reserve1, dataMint?.reserve0)
                setAmountInput(new BigNumber(amountA).toString())
            }
        }
    }
    
    const handleSelectMaxOrPaste = async(newValue:string) => {
        const amount = newValue ?? dataCurrent?.currentIn?.dataUser?.balanceOf
        await setIsChangeOutput(true)
        if(activeTab === ACTION_ACTIVETAB.MINT){
            await setAmountInput(new BigNumber(amount).toString())
            const amountB = new BigNumber(amount).dividedBy(renderVndcPerUsdc)
            await setAmountOutput(new BigNumber(amountB).toString())
        } 
        if(activeTab === ACTION_ACTIVETAB.REDEEM) {
            if(new BigNumber(amount).isGreaterThanOrEqualTo(dataMint?.totalVndcCanRedeem)){
                await setAmountInput(dataMint?.totalVndcCanRedeem)
                const amountB = getAmountOutFromLP(dataMint?.totalVndcCanRedeem, dataMint?.lpPerUser, dataMint?.totalVndcCanRedeem, dataMint?.totalLp, dataMint?.reserve1)
                await setAmountOutput(new BigNumber(amountB).toString())
            } else {
                await setAmountInput(amount)
                const amountB = getAmountOutFromLP(amount, dataMint?.lpPerUser, dataMint?.totalVndcCanRedeem, dataMint?.totalLp, dataMint?.reserve1)
                await setAmountOutput(new BigNumber(amountB).toString())
            }
        }
        await setIsChangeOutput(false)
    }

    const isNotApprove = useMemo(() => {
        if( new BigNumber(dataCurrent?.currentIn?.dataUser?.allowance).isLessThan(amountInput) || new BigNumber(dataCurrent?.currentIn?.dataUser?.allowance).isEqualTo(0) ) return !false
        return false
    },[amountInput, dataCurrent?.currentIn?.dataUser?.allowance])

    const { handleApprove, handleMint, handleRedeem, pendingTx, pendingRedeem } = useActions(tokenVNDC?.address, chainId)

    const isNotBalance = useMemo(() => {
        if(activeTab === ACTION_ACTIVETAB.MINT) {
            if(new BigNumber(amountInput).isGreaterThan(dataCurrent?.currentIn?.dataUser?.balanceOf)) return !false
        }
        if(activeTab === ACTION_ACTIVETAB.REDEEM) {
            if( new BigNumber(amountInput).isGreaterThan(new BigNumber(dataCurrent?.currentIn?.dataUser?.balanceOf).isGreaterThanOrEqualTo(dataMint?.totalVndcCanRedeem) ? dataMint?.totalVndcCanRedeem : dataCurrent?.currentIn?.dataUser?.balanceOf )) return !false
        }
        return false
    },[activeTab, amountInput, dataCurrent?.currentIn?.dataUser?.balanceOf, dataMint?.totalVndcCanRedeem])

    const isMin = useMemo(() => {
        if(activeTab === ACTION_ACTIVETAB.MINT) {
            if( new BigNumber(amountInput).isLessThan(dataMint?.mintAmountMint) ) return !false
        }
        return false
    },[activeTab, amountInput, dataMint?.mintAmountMint])

    const isCanRedeem = useMemo(() => {
        if(activeTab === ACTION_ACTIVETAB.REDEEM) {
            if( new BigNumber(amountInput).isGreaterThan(new BigNumber(dataCurrent?.currentIn?.dataUser?.balanceOf).isGreaterThanOrEqualTo(dataMint?.totalVndcCanRedeem) ? dataMint?.totalVndcCanRedeem : dataCurrent?.currentIn?.dataUser?.balanceOf) ) return !false
        }
        return false
    },[activeTab, amountInput, dataCurrent?.currentIn?.dataUser?.balanceOf, dataMint?.totalVndcCanRedeem])

    const isZero = useMemo(() =>{
        if(new BigNumber(amountInput).isEqualTo(0) || new BigNumber(amountInput).isNaN()) return !false
        return false
    },[amountInput])

    useEffect(() => {
        setAmountInput('')
        setAmountOutput('')
    },[account, activeTab])

    const error = useMemo(() => {
        if( isNotBalance ) {
            if(activeTab === ACTION_ACTIVETAB.REDEEM && new BigNumber(dataMint?.totalVndcCanRedeem).isLessThan(dataCurrent?.currentIn?.dataUser?.balanceOf)) {
                return {
                    isErr: !false,
                    text: "Your redeemable is not enough!",
                    isLeft: false
                }
            }
            return {
                isErr: !false,
                text: "Your balance is not enough!",
                isLeft: !false
            }
        } 
        if( isMin ) {
            return {
                isErr: !false,
                text: `Amounts must be greater than ${dataMint?.mintAmountMint} ${tokenUSDC?.symbol?.toUpperCase()}`,
                isLeft: !false
            }
        }
        if(isCanRedeem) {
            return {
                isErr: !false,
                text: `Amounts must be less than ${formatNumber(dataCurrent?.currentIn?.dataUser?.balanceOf > dataMint?.totalVndcCanRedeem ? dataMint?.totalVndcCanRedeem : dataCurrent?.currentIn?.dataUser?.balanceOf, tokenVNDC?.symbol, chainId)} ${tokenVNDC?.symbol?.toUpperCase()}`,
                isLeft: !false
            }
        }
        if( new BigNumber(amountOutput).isEqualTo(0)) {
            return {
                isErr: !false,
                text: `Amounts USDC must be less than 0`,
                isLeft: !false
            }
        }
        return null
    },[isNotBalance, isMin, isCanRedeem, amountOutput, activeTab, dataMint?.mintAmountMint, dataMint?.totalVndcCanRedeem, tokenUSDC?.symbol, dataCurrent?.currentIn?.dataUser?.balanceOf, tokenVNDC?.symbol, chainId])

    const erc20Contract = useVNDCContract(activeTab === ACTION_ACTIVETAB.MINT ? tokenUSDC?.address : tokenVNDC?.address)
    const onMint = async() => {
        const res = await handleMint(amountInput, amountOutput, tokenUSDC?.address, DEFAULT_SLIPPAGE)
        if(res?.status === 200) {
            setAmountInput('')
            setAmountOutput('')
        }
    }

    const onRedeem = async() => {
        const res = await handleRedeem(amountInput, tokenUSDC?.address, DEFAULT_SLIPPAGE)
        if(res?.status === 200) {
            setAmountInput('')
            setAmountOutput('')
        }
    }

    return (
        <ContainerAction>
            <Flex width="100%" justifyContent="center" style={{gap:"12px"}} >
                <CsButtonSecondary
                    width="120px"
                    variant={ activeTab === ACTION_ACTIVETAB.MINT ? "primary" : "secondary"}
                    isActive={ activeTab === ACTION_ACTIVETAB.MINT ? !false : false}
                    onClick={() => handleChangeTab(ACTION_ACTIVETAB.MINT) }
                >
                    MINT
                </CsButtonSecondary>
                <CsButtonSecondary
                    width="120px"
                    variant={ activeTab === ACTION_ACTIVETAB.REDEEM ? "primary" : "secondary"}
                    isActive={ activeTab === ACTION_ACTIVETAB.REDEEM ? !false : false}
                    onClick={() => handleChangeTab(ACTION_ACTIVETAB.REDEEM) }
                >
                    REDEEM
                </CsButtonSecondary>
            </Flex>
            <Flex 
                width="100%" 
                flexDirection="column" 
                style={{gap:"1.5rem"}} 
                background={theme.colors.backgroundAlt2}
                borderRadius="12px"
                padding="2rem 1rem"
            >
                <InputBalance
                    amount={amountOutput === undefined ? "" : amountInput}
                    handleChangeAmount={(newAmount:string) => handleChangeInput(newAmount)}
                    isShowMax
                    balance={dataCurrent?.currentIn?.dataUser?.balanceOf}
                    symbol={dataCurrent?.currentIn?.token?.symbol?.toUpperCase()}
                    src={`/images/coins/${dataCurrent?.currentIn?.token?.symbol?.toLowerCase()}.png`}
                    isErrBalance={ error?.isErr}
                    errText={ error?.text }
                    handleSelectMaxOrPaste={handleSelectMaxOrPaste}
                    isRedeem={activeTab === ACTION_ACTIVETAB.REDEEM}
                    totalCanRedeem={dataMint?.totalVndcCanRedeem}
                    id="input"
                    isInput
                    chainId={chainId}
                    isLeft={error?.isLeft}
                />

                <InputBalance
                    amount={ amountInput === undefined ? "" : amountOutput}
                    handleChangeAmount={(newAmount:string) => handleChangeOutput(newAmount)}
                    balance={dataCurrent?.currentOut?.dataUser?.balanceOf}
                    symbol={dataCurrent?.currentOut?.token?.symbol?.toUpperCase()}
                    src={`/images/coins/${dataCurrent?.currentOut?.token?.symbol?.toLowerCase()}.png`}
                    id="output"
                    isInput={false}
                    chainId={chainId}
                />
                <Flex alignItems="center" style={{gap:"12px"}}>
                    <Text bold color="text">1 VNDC</Text>
                    <Text bold color="text">~</Text>
                    <Text bold color="text">{formatNumber(renderVndcPerUsdc, tokenUSDC?.symbol, chainId)} {tokenUSDC?.symbol?.toUpperCase()}</Text>
                </Flex>
                { activeTab === ACTION_ACTIVETAB.MINT &&
                    <Flex
                        width="auto"
                        style={{gap:"12px"}}
                        alignItems="center"
                    >
                        <Text color="text">Bonus ({BONUS*100}%):</Text>
                        <Text color="text">{formatNumber(renderBonus, tokenVNDC?.symbol, chainId)} {tokenVNDC?.symbol?.toUpperCase()}</Text>
                    </Flex>
                }
                { activeTab === ACTION_ACTIVETAB.REDEEM &&
                    <Flex
                        width="auto"
                        style={{gap:"12px"}}
                        alignItems="center"
                    >
                        <Text color="text">Fee ({Number(dataMint?.feeWithdraw)*100}%):</Text>
                        <Text color="text">{formatNumber(renderFee, tokenUSDC?.symbol, chainId)} {tokenUSDC?.symbol?.toUpperCase()}</Text>
                    </Flex>
                }
                <Flex>
                    { dataMint?.isPaused &&
                        <Text small color="warning43">Contract paused</Text>
                    }
                    { ( !dataMint?.isWhitelist && account ) &&
                        <Text small color="warning43">Your are not on the whitelist</Text>
                    }
                </Flex>
                <Flex width="100%" justifyContent="center">
                    { account ?
                        <>
                            { isNotApprove ?
                                <Button
                                    width={["100%",,"280px"]}
                                    onClick={() => handleApprove(erc20Contract, tokenVNDC?.address)}
                                    disabled={pendingTx || dataMint?.isPaused || !dataMint?.isWhitelist}
                                    endIcon={pendingTx ? <AutoRenewIcon color="white" spin/> : null}
                                >
                                    APPROVE
                                </Button>
                            :
                                <>
                                    { activeTab === ACTION_ACTIVETAB.MINT ?
                                        <Button
                                            width={["100%",,"280px"]}
                                            disabled={pendingTx || isNotBalance || account?.length === 0 || isZero || dataMint?.isPaused || !dataMint?.isWhitelist || isMin}
                                            onClick={onMint}
                                            endIcon={pendingTx ? <AutoRenewIcon spin color="white" /> :null}
                                        >
                                            MINT
                                        </Button>
                                    :
                                        <Button
                                            width={["100%",,"280px"]}
                                            disabled={pendingRedeem || isNotBalance || account?.length === 0 || isZero || dataMint?.isPaused || !dataMint?.isWhitelist || isCanRedeem}
                                            onClick={onRedeem}
                                            endIcon={pendingRedeem ? <AutoRenewIcon spin color="white" /> :null}
                                        >
                                            REDEEM
                                        </Button>
                                    }
                                </>
                            }
                        </>
                    :
                        <ConnectWalletButton 
                            width="280px"
                        />
                    }
                </Flex>
            </Flex>
            
        </ContainerAction>
    )
}

export default Actions