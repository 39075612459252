import { Button, Flex, Input, Skeleton, Text, useMatchBreakpoints } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import useTheme from "hooks/useTheme";
import React, { useMemo } from "react";
import { formatNumber, formatNumberOnlyVNDC } from "utils/formatNumber";
import styled from "styled-components";
import { CsInputGroup, CsNumericFormat } from "./styles";
import { isVNDC } from "../ultils/getToken";


interface Props {
    amount: string,
    handleChangeAmount: (newAmount:string) => void
    isShowMax?:boolean,
    balance: string,
    symbol:string
    src:string,
    isErrBalance?:boolean
    errText?:string
    handleSelectMaxOrPaste?:(newValue?:string) => void,
    isRedeem?: boolean,
    totalCanRedeem?: string,
    id: string,
    isInput: boolean,
    chainId: number,
    isLeft?: boolean
    
}
const InputBalance:React.FC<Props> = ({
    amount,
    handleChangeAmount,
    isShowMax,
    balance,
    symbol,
    src,
    isErrBalance,
    errText,
    handleSelectMaxOrPaste,
    isRedeem,
    totalCanRedeem,
    id,
    isInput, chainId, isLeft
}) => {
    const { theme } = useTheme()
    const { isMobile } = useMatchBreakpoints()

    const balanceByTab = useMemo(() => {
        if(isRedeem) {
            if(new BigNumber(balance).isGreaterThanOrEqualTo(totalCanRedeem)) {
                return totalCanRedeem
            }
            return balance
        }
        return balance
    },[balance, isRedeem, totalCanRedeem])
    
    return (
        <Flex
            width="100%"
            background={`${theme.colors.backgroundInput}`}
            borderRadius="12px"
            padding="1rem"
            flexDirection="column"
            style={{gap:"12px"}}
        >
            <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <Flex width={["15%",,"12.5%"]}>
                    { symbol ?
                        <CsImgCurrent src={src} alt="token"/>

                    :
                        <Skeleton variant="circle" height="40px" width="40px" />
                    }
                </Flex>
                <Flex width={["85%",,"87.5%"]} alignItems={["flex-start",,"center"]} justifyContent="flex-end" style={{gap:"4px"}}>
                    <CsInputGroup
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{gap: isMobile ? "6px" : "0px"}}
                    >
                        <CsNumericFormat
                            value={ isVNDC(symbol, chainId) ? new BigNumber(amount).decimalPlaces(0,1).toString() : new BigNumber(amount).decimalPlaces(6,1).toString() }
                            decimalScale={isVNDC(symbol, chainId) ? 0 : 6}
                            id={id}
                            thousandSeparator={!false}
                            placeholder={isVNDC(symbol, chainId) ? "0" : "0.0"}
                            onPaste={(e) => {
                                e.preventDefault()
                                handleChangeAmount(e.clipboardData.getData("text")?.split(",").join(""))
                            }}
                            onChange={(evt) => {
                                const value = evt?.target?.value ;
                                handleChangeAmount(value.split(",").join(""))
                            }}
                            isAllowed={() => {
                                return true;
                            }}
                            maxLength={35}
                            allowNegative={false}
                        />

                        { isShowMax &&
                            <Button
                                variant="text"
                                onClick={() => handleSelectMaxOrPaste()}
                                disabled={new BigNumber(balanceByTab).isEqualTo(0)}
                                style={{fontWeight: 600}}
                            >
                                MAX
                            </Button>
                        }
                        
                    </CsInputGroup>
                </Flex>
            </Flex>
            <Flex
                width="100%"
                justifyContent="space-between"
                flexDirection={['column',,"row"]}
                style={{gap:isMobile ? "12px" : '0px'}}
            >
                <Flex
                    width={["100%",,"50%"]}
                    flexDirection={["row",,"column"]}
                    style={{gap:"8px"}}
                >
                    <Text width={["20%",,"auto"]} fontSize={['13px', , '16px']} color="backgroundDisabled" bold>Balance{isMobile && ":"}</Text>
                    <Text width={["80%",,"auto"]} fontSize={['13px', , '16px']} bold color="text" style={{wordBreak:"break-word"}} textAlign={["right",,"left"]}>{formatNumber(balance, symbol, chainId)} {symbol}</Text>
                </Flex>
                { isRedeem &&
                    <Flex
                        width={["100%",,"50%"]}
                        flexDirection={["row",,"column"]}
                        justifyContent={isMobile ? "space-between" : "flex-start"}
                        style={{gap:"8px"}}
                    >
                        <Text width={["55%",,"auto"]} fontSize={['13px', , '16px']} color="backgroundDisabled" bold textAlign={["left",,"right"]}>Redeemable{isMobile && ":"}</Text>
                        <Flex width={["45%",,"auto"]} alignItems='center' flexWrap="wrap" justifyContent={["flex-end",, "flex-end"]}>
                            <Text fontSize={['13px', , '16px']} bold color="text" style={{wordBreak:"break-word"}} textAlign={["right",,"right"]}>{formatNumberOnlyVNDC(totalCanRedeem)} VNDC</Text>
                        </Flex>
                    </Flex>
                }
                
            </Flex>
            <Flex width="100%" justifyContent={isLeft ? "flex-start" : "flex-end" }>
                { (isErrBalance && !new BigNumber(amount).isNaN() ) &&
                    <Text small color="warning43">{errText}</Text>
                }
            </Flex>
        </Flex>
    )
}
const CsImgCurrent = styled.img`
    height: 40px;
    width:40px;
    @media screen and (max-width: 600px){
        height: 30px;
        width: 30px;
    }
`
export default InputBalance