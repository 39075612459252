import { Flex, LinkExternal, Text, useMatchBreakpoints } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
// eslint-disable-next-line lodash/import-scope
import _ from "lodash";
import React from "react";
import { OrderItem } from "state/action/types";
import { getBlockExploreLink } from "utils";
import { formatNumber } from "utils/formatNumber";
import { renderDate } from "../ultils/getDate";
import NodataOrNotConnectWallet from "./Nodata";
import { ContainerRow, CsText, Row } from "./styles";
import { getTokenUSDC, getTokenVNDC } from "../ultils/getToken";

interface Props {
    account:string,
    orders: OrderItem[],
    chainId: number
}
const Table:React.FC<Props> = ({
    account,
    orders,
    chainId
}) => {
    const { isMobile } = useMatchBreakpoints()
    const tokenVNDC = getTokenVNDC(chainId)
    const tokenUSDC = getTokenUSDC(chainId)
    return (
        <Flex width='100%' height="auto" flexDirection="column" borderRadius="12px" overflow="hidden">
            <Row isHeader>
                <Flex width="100%" alignItems="center">
                    <CsText bold color="backgroundDisabled">Transaction</CsText>
                </Flex>
                <Flex width="100%" alignItems="center" justifyContent="flex-start">
                    <CsText bold color="backgroundDisabled">VNDC</CsText>
                </Flex>
                <Flex width="100%" alignItems="center" justifyContent="flex-start">
                    <CsText bold color="backgroundDisabled">USDT</CsText>
                </Flex>
                <Flex width="100%" alignItems="center" justifyContent="flex-start">
                    <CsText bold color="backgroundDisabled">Time</CsText>
                </Flex>
            </Row>
            { orders?.length > 0 ?
                <ContainerRow>
                    <Flex width="100%" flexDirection="column" height="auto" style={{gap: isMobile ? "1rem" : "0rem"}}>
                        { orders?.map((dt) => {
                                return (
                                    <Row style={{backgroundColor: '#fdf9f9'}}>
                                        <Flex width="100%" alignItems="center" justifyContent="space-between">
                                            <Text  bold display={["block",,"none"]} color="backgroundDisabled">Transaction</Text>
                                            <CsText color="text" bold>{_.upperFirst(dt?.method)}</CsText>
                                        </Flex>
                                        <Flex width="100%" alignItems="center" justifyContent={["space-between", , "flex-start"]} padding={["0px",,"0px 12px 0px 0px"]}>
                                            <Text bold display={["block",,"none"]} color="backgroundDisabled">VNDC</Text>
                                            <CsText color="text" bold style={{wordBreak:"break-word"}}>{formatNumber(new BigNumber(dt?.vndcAmount).toString(), tokenVNDC?.symbol, chainId)}</CsText>
                                        </Flex>
                                        <Flex width="100%" alignItems="center" justifyContent={["space-between", , "flex-start"]} padding={["0px",,"0px 12px 0px 0px"]}>
                                            <Text bold display={["block",,"none"]} color="backgroundDisabled">USDT</Text>
                                            <CsText color="text" bold style={{wordBreak:"break-word"}}>{formatNumber(new BigNumber(dt?.usdcAmount).dividedBy(1e18).toString(), tokenUSDC?.symbol, chainId)}</CsText>
                                        </Flex>
                                        <Flex width="100%" alignItems="center" justifyContent="space-between">
                                            <Text bold display={["block",,"none"]} color="backgroundDisabled">Time</Text>
                                            <Flex alignItems="center" style={{gap:"4px"}} justifyContent="flex-end">
                                                <CsText color="text" bold>{renderDate(dt?.timeStamp*1000)}</CsText>
                                                <LinkExternal
                                                    href={getBlockExploreLink(dt?.transactionHash, 'transaction', chainId)}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Row>
                                )
                            })
                        }
                    </Flex>
                </ContainerRow>
            :
                <NodataOrNotConnectWallet account={account} />
            }
           
        </Flex>
    )
}

export default Table
