import BigNumber from "bignumber.js"
import { isVNDC } from "views/Home/ultils/getToken"

export const formatNumber = (value:string|number, symbold:string, chainId:number, minNum = 6, maxNum = 6) => {
    if(!new BigNumber(value).isNaN()) {
        if(isVNDC(symbold, chainId)) {
            return new BigNumber(value).decimalPlaces(0,1).toNumber().toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        }
        return new BigNumber(value).decimalPlaces(6,1).toNumber().toLocaleString('en', { minimumFractionDigits: minNum, maximumFractionDigits: maxNum })
    } 
    return "0"
}

export const formatNumberOnlyVNDC = (value:string|number) => {
    if(!new BigNumber(value).isNaN()) {
        return new BigNumber(value).decimalPlaces(0,1).toNumber().toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    } 
    return "0"
}