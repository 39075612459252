import { ChevronLeftIcon, Flex } from '@pancakeswap/uikit'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

export const Paginate = ({ total, pageSize, pageActive, handlePageClick }) => {
    return (
        <CustomFlex>
            <ReactPaginate
                nextLabel={<ChevronLeftIcon style={{ transform: 'rotate(180deg)' }} />}
                previousLabel={<ChevronLeftIcon />}
                onPageChange={handlePageClick}
                pageCount={total}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                forcePage={pageActive}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
            />
        </CustomFlex>
    )
}

const CustomFlex = styled(Flex)`
    width: 100%;
    margin-bottom: 1rem;
    justify-content: center;
    height: 62px;
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 600px) {
            width: 100%;
            justify-content: center;
        }
        * {
            list-style-type: none;
        }
    }
    .page-item {
        margin: 0 0.375rem;
        &:first-child,
        &:last-child {
            a {
                &:hover {
                    color: #f78e01;
                    border: none !important;
                }
            }
        }
        &:first-child,
        &:last-child {
            a {
                border: none;
                background: none !important;
            }
        }
        &:last-child.disabled,
        &:first-child.disabled {
            cursor: not-allowed;
        }
        border: 1px solid #fc9111;
        border-radius: 50%;
    }
    .page-link {
        width: 42px;
        height: 42px;
        display: flex;
        background: transparent;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.gold};
        border: 1px solid #fc9111;
        svg path {
            fill: ${({ theme }) => theme.colors.text};
        }
        @media screen and (max-width: 768px) {
            padding: 12px;
        }
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            /* background: ${({ theme }) => theme.colors.gold}; */
            /* color: ${({ theme }) => theme.colors.white}; */
        }
        @media screen and (max-width:600px) {
            width: 32px;
            height: 32px; 
            font-size: 12px;
            padding: 8px;
        }
    }
    .page-item.disabled .page-link {
        /* background: ${({ theme }) => theme.colors.disabled}; */
        cursor: not-allowed !important;
        opacity: 0.7;
        pointer-events: none;
        svg {
            border-radius: 100%;
        }
        svg path {
            fill: #9596a6 !important;
        }
    }
    .page-item.active .page-link {
        border: none;
        background: ${({ theme }) => theme.colors.gradientGold};
        color: ${({ theme }) => theme.colors.white};
    }
`
