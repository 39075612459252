import { MaxInt256 } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { useTranslation } from '@pancakeswap/localization'
import { useToast } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { useVNDCContract } from 'hooks/useContract'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { fetchTxHash } from 'state/action/actions'
import { formatNumber } from 'utils/formatNumber'
import { getTokenVNDC } from '../ultils/getToken'


export const useActions = (vndcTokenAddress:string, chainId: number) => {
    const { toastSuccess, toastError } = useToast()
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [pendingTx, setPendingTx] = useState(false)
    const [pendingRedeem, setPendingRedeem] = useState(false)
    const erc20Contract = useVNDCContract(vndcTokenAddress)
    const tokenVNDC = getTokenVNDC(chainId)
    const handleApprove = useCallback(async (contract: Contract | null, spender:string) => {
        setPendingTx(true)
        try {
            const tx = await callWithMarketGasPrice(contract, 'approve', [spender, MaxInt256])
            const receipt = await tx.wait()
            if (receipt.status) {
                toastSuccess(
                    t(`Contract enabled`),
                    <ToastDescriptionWithTx txHash={receipt.transactionHash} />
                )
                dispatch(fetchTxHash({txHash:receipt.transactionHash}))
            } else {
                // user rejected tx or didn't go thru
                toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            }
            return {
                status: receipt.status ? 200 : 403
            }
        } catch (e) {
            console.error(e)
            toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            return {
                status: 403
            }
        } finally {
            setPendingTx(false)
        }
    }, [callWithMarketGasPrice, dispatch, t, toastError, toastSuccess]);

    const handleMint = useCallback(async (amountIn:string, amountOutput: string, currentcyInAddress: string, slippage:number) => {
        setPendingTx(true)
        const converAmountIn = new BigNumber(new BigNumber(amountIn)).decimalPlaces(18,1).multipliedBy(1E18).toString()
        try {
            const tx = await callWithMarketGasPrice(erc20Contract, 'mintWhitelist', [converAmountIn, currentcyInAddress, slippage])
            const receipt:any = await tx.wait()
            const amountAlert = new BigNumber(receipt?.events[receipt?.events?.length-1]?.args?.amountsOut?.toString())?.toString() ?? amountOutput
            if (receipt.status) {
                toastSuccess(
                    `${formatNumber(amountAlert, tokenVNDC?.symbol, chainId )} VNDC minted successfully`,
                    <ToastDescriptionWithTx txHash={receipt.transactionHash} />
                )
                dispatch(fetchTxHash({txHash:receipt.transactionHash}))
            } else {
                // user rejected tx or didn't go thru
                toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            }
            return {
                status: receipt.status ? 200 : 403
            }
        } catch (e) {
            console.error(e)
            toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            return {
                status: 403
            }
        } finally {
            setPendingTx(false)
        }
    }, [callWithMarketGasPrice, chainId, dispatch, erc20Contract, t, toastError, toastSuccess, tokenVNDC?.symbol]);

    const handleRedeem = useCallback(async (amountIn:string, currentcyInAddress: string, slippage:number) => {
        setPendingRedeem(true)
        const converAmountIn = new BigNumber(new BigNumber(amountIn)).decimalPlaces(0,1).toString()
        try {
            const tx = await callWithMarketGasPrice(erc20Contract, 'withdrawWhitelist', [converAmountIn, currentcyInAddress, slippage])
            const receipt = await tx.wait()
            if (receipt.status) {
                toastSuccess(
                    `${formatNumber(amountIn, tokenVNDC?.symbol, chainId)} VNDC redeemed successfully`,
                    <ToastDescriptionWithTx txHash={receipt.transactionHash} />
                )
                dispatch(fetchTxHash({txHash:receipt.transactionHash}))
            } else {
                // user rejected tx or didn't go thru
                toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            }
            return {
                status: receipt.status ? 200 : 403
            }
        } catch (e) {
            console.error(e)
            toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            return {
                status: 403
            }
        } finally {
            setPendingRedeem(false)
        }
    }, [callWithMarketGasPrice, chainId, dispatch, erc20Contract, t, toastError, toastSuccess, tokenVNDC?.symbol]);

    return { handleApprove, handleMint, handleRedeem, pendingTx, pendingRedeem }
}
