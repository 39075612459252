import { useTranslation } from '@pancakeswap/localization'
import React from 'react'
import MetaTags from 'react-meta-tags'
import styled from 'styled-components'
// import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useRouter } from 'next/router'
import { Flex, Box } from '@pancakeswap/uikit'
import { MENU_HEIGHT } from '@pancakeswap/uikit/src/widgets/Menu/config'

const PageMeta = () => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { pathname } = useRouter()
  const pageMeta = getCustomMeta(pathname, t, locale) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  const pageTitle = title

  return (
    <MetaTags>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
    </MetaTags>
  )
}

const PageFullWidth: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <Wrapper>
      <PageMeta />
      <MaskBackground/>
      <CsContainer>{children}</CsContainer>
    </Wrapper>
  )
}

export default PageFullWidth

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-top: -${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.background};
  @media screen and (max-width: 600px) {
    padding-bottom: 0px;
  }
`
const MaskBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.background};
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
`
const CsContainer = styled(Box)`
  max-width: 1320px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 30px;
  z-index: 101;
  margin-top: ${MENU_HEIGHT}px;
  @media screen and (min-width: 769px) and (max-width: 1280px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  @media screen and (min-width: 601px) and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
