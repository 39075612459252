import BigNumber from "bignumber.js"

export const getAmountOutFromLP = (amountIn: string, lpPerUser: string, totalVNDCPerUserMap: string, totalLp: string, reserve1: string ) => {
    const amountLP = new BigNumber(new BigNumber(amountIn).multipliedBy(lpPerUser)).dividedBy(totalVNDCPerUserMap)
    return new BigNumber(new BigNumber(reserve1).multipliedBy(amountLP)).dividedBy(totalLp)?.toString()
}


export const getAmountInFromLP = (amountIn: string, totalLp: string, reserve1: string, reserve0: string ) => {
    const amountLP = new BigNumber(new BigNumber(amountIn).multipliedBy(totalLp)).dividedBy(reserve1)
    return new BigNumber(new BigNumber(reserve0).multipliedBy(amountLP)).dividedBy(totalLp).decimalPlaces(0,1).toString()
}
