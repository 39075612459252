import { ChainId } from "@pancakeswap/sdk";
import { bscTestnetTokens, bscTokens, onusMainnetTokens, onusTestnetTokens } from "@pancakeswap/tokens";

export const getTokenVNDC = (chainId:number) => {
    switch (chainId) {
        case ChainId.BSC_TESTNET:
            return bscTestnetTokens.VNDC
        case ChainId.ONUS:
            return onusMainnetTokens.vndc
        case ChainId.ONUS_TESTNET:
            return onusTestnetTokens.vndc    
        default:
            return onusMainnetTokens.vndc
    }
}

export const getTokenUSDC = (chainId:number) => {
    switch (chainId) {
        case ChainId.BSC_TESTNET:
            return bscTestnetTokens.usdc
        case ChainId.ONUS:
            return onusMainnetTokens.usdt
        case ChainId.ONUS_TESTNET:
            return onusTestnetTokens.usdt    
        default:
            return onusMainnetTokens.usdt
    }
}

export const isVNDC = (symbold:string, chainId: number) => {
    const VNDC = getTokenVNDC(chainId)
    if(symbold?.toLowerCase() === VNDC?.symbol?.toLowerCase()) {
        return !false
    } 
    return false
}