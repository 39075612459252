import { Provider } from '@ethersproject/providers';
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect';
import { useDispatch, useSelector } from 'react-redux';
import getLpAddress from 'utils/getLpAddress';
import { getTokenUSDC, getTokenVNDC } from 'views/Home/ultils/getToken';
import { AppDispatch, AppState } from '../index';
import { fetchCurrent, fetchDataHistory, fetchDataMint } from './actions';
import { fetchDataUser, fetchHistoryData, getDataMint } from './fetchData';
import { CURRENTITEM } from "./types";

export const GetDataActions = () => { 
    const actions = useSelector<AppState, AppState['actions']>((state) => state?.actions)
    return actions
}


export const UseActions = ( currentItem: CURRENTITEM, account:string, chainId:number, txHash:string) => {
    const dispatch = useDispatch<AppDispatch>()
    useSlowRefreshEffect(() => {
        (async () => {
            const tokenVNDC = getTokenVNDC(chainId)
            const tokenUSDC = getTokenUSDC(chainId)
            const pairAddress = getLpAddress(tokenVNDC, tokenUSDC)
            const [ resultIn, resultOut, dataMint ] = await Promise.all([
                fetchDataUser(currentItem?.currentInput, tokenVNDC, account, chainId),
                fetchDataUser(currentItem?.currentOutput, tokenVNDC, account, chainId),
                getDataMint(tokenVNDC, tokenUSDC, chainId, account, pairAddress)
            ])
            dispatch(fetchDataMint(dataMint))
            dispatch(fetchCurrent({dataCurrent: {
                currentIn: {
                    token: currentItem?.currentInput,
                    dataUser: resultIn?.dataUser
                },
                currentOut: {
                    token: currentItem?.currentOutput,
                    dataUser: resultOut?.dataUser
                }
            }}))
        })();
    }, [account, chainId, currentItem, dispatch, txHash])
}

export const UseHistory = ( skip: number, limit: number, method:string, chainId:number, walletAddress:string, txHash:string, provider: Provider|null ) => {
    const dispatch = useDispatch<AppDispatch>()
    useSlowRefreshEffect(() => {
        (async () => {
            const data = await fetchHistoryData(skip, limit, method, chainId, walletAddress, provider)
            dispatch(fetchDataHistory(data))
        })();
    }, [chainId, dispatch, limit, method, skip, walletAddress, txHash, provider])
}