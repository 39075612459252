import { Button, Flex, Text } from "@pancakeswap/uikit";
import styled from "styled-components";
import { NumericFormat  } from 'react-number-format';

export const CsFlex = styled(Flex)`
    width: 100%;
    height: 100%;
    justify-content:space-between;
    @media screen and (max-width:768px) {
        flex-direction: column-reverse;
        gap:2rem;
    }
`

export const ContainerHistory = styled(Flex)`
    flex-direction: column;
    width: 54%;
    height: 100%;
    gap: 2rem;
    @media screen and (max-width:768px) {
        width: 100%;

    }
`

export const ContainerAction = styled(Flex)`
    flex-direction: column;
    width: 44%;
    height: 100%;
    gap:1.5rem;
    @media screen and (max-width:768px) {
       width : 100% ;
    }
`

export const Row = styled.div<{isHeader?:boolean}>`
    width: 100%;
    height: 68px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    border-top: ${({ isHeader, theme }) => isHeader ? `1px solid ${theme.colors.cardBorder}` : "none"};
    display:grid;
    grid-template-columns:20% 27% 25% 27%;
    /* background: ${({ theme }) => theme.colors.backgroundAlt2}; */
    @media screen and (max-width:1280px) and (min-width: 769px) {
        grid-template-columns: 20% 27% 25% 27%;
    }
    @media screen and (max-width:600px) {
        display: ${({ isHeader }) => isHeader ? `none` : "grid"};
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
        border-radius: 12px;
        height:auto;
        padding: 1rem;
    }
`

export const ContainerRow = styled(Flex)`
    width: 100%;
    flex-direction: column;
    height: 100%;
    min-height: 80px;
    max-height: 275px;
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 4px;
        box-shadow: none;
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.gradientGold}; 
        border-radius: 2px;
    }
    @media screen and (max-width:768px) {
        height: 100%;
        max-height: 100%;
        overflow-y: hidden;
    }
`

export const CsNumericFormat = styled(NumericFormat )`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-size: 20px;
    outline: 0;
    padding: 0 0;
    height: 48px;
    width: 100%;
    font-weight: bold;
    border: none;
    &::placeholder {
        color: ${({ theme }) => theme.colors.backgroundDisabled};
    }
    @media screen and (max-width:600px) {
        font-size: 14px;
    }
`
export const CsInputGroup = styled(Flex)`
    > input {
        border: none;
        height: 48px;
        width: 100%;
        background: ${({ theme }) => theme.colors.backgroundInput};
        color: ${({ theme }) => theme.colors.text};
        &::placeholder {
            color: ${({ theme }) => theme.colors.text};
        }
        font-size: 24px;
        font-weight: 600;
        &:focus-visible {
            outline: none;
        }
    }
`

export const CsText = styled(Text)`
    @media screen and (max-width:1280px) and (min-width:1000px) {
        font-size: 12px;
    }
`

export const CsButtonSecondary = styled(Button)<{isActive: boolean}>`
    background: ${({ isActive }) => isActive ? "linear-gradient(90deg, #F1A40D 0.12%, #FEEA38 100.12%)" : "linear-gradient(white, white) padding-box, linear-gradient(to right,#F1A30C, #FEEA38) border-box;" };
    border-radius: 4px;
    border: ${({ isActive }) => isActive ? "none" : "1px solid transparent"};
    &:hover {
        border: none;
    }
`