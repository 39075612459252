import { Provider } from "@ethersproject/providers";
import { Token } from "@pancakeswap/sdk";
import axios from "axios";
import BigNumber from "bignumber.js";
import { ERC20_ABI } from "config/abi/erc20";
import lpAbi from "config/abi/lpAbi.json";
import routerAbi from "config/abi/routerAbi.json";
import vndcAbi from "config/abi/vndcAbi.json";
import multicall from "utils/multicall";
import { DataMint, DataUser, HistoryType, OrderItem } from "./types";

export const fetchDataUser = async (token: Token, tokenVNDC: Token, account:string, chainId: number): Promise<{dataUser: DataUser}> => {
    if(account?.length > 0) {
        const calls = [
            {
                address: token?.address,
                name: 'allowance',
                params: [account, tokenVNDC?.address]
            },
            {
                address: token?.address,
                name: 'balanceOf',
                params: [account]
            }
        ]
        const [resultAllowance, resultBalance ] = await multicall(ERC20_ABI, calls, chainId )
        
        return { dataUser : {
                balanceOf: new BigNumber(resultBalance?.toString()).dividedBy(10**token?.decimals).toString(),
                allowance: new BigNumber(resultAllowance?.toString()).dividedBy(10**token?.decimals).toString(),
            }
        }  
    }
    return { dataUser : {
            balanceOf: "0",
            allowance: "0"
        }
    }
}

export const getDataMint = async (tokenVNDC: Token, tokenUSDC: Token, chainId: number, account:string, pairAddress: string): Promise<{dataMint: DataMint}> => {
    try {
        const getDataWhite = async() => {
            if(account?.length > 0) {
                const callsWhitelist = [
                    {
                        address: tokenVNDC?.address,
                        name: 'whitelistMap',
                        params: [account]
                    }
                ]
                
                const [result] = await multicall(vndcAbi, callsWhitelist, chainId)
                return result[0]
            } 
            return false
        }
        const getDataTotalCanRedeem = async() => {
            if(account?.length > 0) {
                const callsWhitelist = [
                    {
                        address: tokenVNDC?.address,
                        name: 'totalVNDCPerUserMap',
                        params: [account, tokenUSDC?.address]
                    },
                    {
                        address: tokenVNDC?.address,
                        name: 'lpPerUser',
                        params: [account, tokenUSDC?.address]
                    }
                ]
                
                const [result, resultLp] = await multicall(vndcAbi, callsWhitelist, chainId)
                return  {
                    totalVndcCanRedeem: new BigNumber(result[0]?.toString()).dividedBy(10**tokenVNDC?.decimals).toString(),
                    lpPerUser: new BigNumber(resultLp[0].toString()).dividedBy(1E18).toString()
                }
            } 
            return  {
                totalVndcCanRedeem: "0",
                lpPerUser: "0"
            }
        }
        const getReserves = async() => {
            if(pairAddress?.length > 0) {
                const calls = [
                    {
                        address: pairAddress,
                        name: 'getReserves',
                        params: []
                    },
                    {
                        address: pairAddress,
                        name: 'totalSupply',
                        params: []
                    }
                ]
                
                const [result, resultTotalLp] = await multicall(lpAbi, calls, chainId)
                
                return {
                    reserve0: new BigNumber(result?._reserve0?.toString()).toString(),
                    reserve1: new BigNumber(result?._reserve1?.toString()).dividedBy(1E18).toString(),
                    totalLp: new BigNumber(resultTotalLp?.toString()).dividedBy(1E18).toString()
                }
            } 
            return {
                reserve0: "0",
                reserve1:"0",
                totalLp: "0"
            }
        }

        const balanceOfByUser = async() => {
            if(account?.length > 0) {
               try {
                    const calls = [
                        {
                            address: pairAddress,
                            name: 'balanceOf',
                            params: [account]
                        }
                    ]
                    
                    const [result] = await multicall(lpAbi, calls, chainId)
                    return new BigNumber(result?.toString()).toString()
               } catch (error) {
                    console.log(error)
                    return "0"
               }
            } 
            return "0"
        }

        const balanceOfCurrency = async() => {
            if(tokenVNDC?.address?.length > 0) {
               try {
                    const calls = [
                        {
                            address: pairAddress,
                            name: 'balanceOf',
                            params: [tokenVNDC?.address]
                        }
                    ]
                    
                    const [result] = await multicall(lpAbi, calls, chainId)
                    return new BigNumber(result?.toString()).toNumber()
               } catch (error) {
                    console.log(error)
                    return 0
               }
            } 
            return 0
        }

        const getTotalSupply = async() => {
            try {
                if(pairAddress?.length > 0) {
                    const calls = [
                        {
                            address: pairAddress,
                            name: 'totalSupply',
                            params: []
                        }
                    ]
                    
                    const [result] = await multicall(lpAbi, calls, chainId)
                    return new BigNumber(result?.toString()).toNumber()
                } 
                return 0
            } catch (error) {
                console.log(error)
                return 0
            }
        }
        const calls = [
            {
                address: tokenVNDC?.address,
                name: 'feeWithdraw',
                params: []
            },
            {
                address: tokenVNDC?.address,
                name: 'paused',
                params: []
            },
            {
                address: tokenVNDC?.address,
                name: 'BASE_RATE',
                params: []
            },
            {
                address: tokenVNDC?.address,
                name: 'bonus',
                params: []
            }
        ] 
        
        const [ whitelist, totalVndcCanRedeem, resultReserves, totalSupply, lpBalance, resBalanceOfCurency ] = await Promise.all([
            getDataWhite(),
            getDataTotalCanRedeem(),
            getReserves(),
            getTotalSupply(),
            balanceOfByUser(),
            balanceOfCurrency()
        ])
        const [resultFee, resultPaused, resultBaseRate, resBonus ] = await multicall(vndcAbi, calls, chainId )
        
        return { dataMint : {
                feeWithdraw: new BigNumber(resultFee?.toString()).plus(resBonus?.toString()).dividedBy(1000).toString(),
                mintAmountMint: "1",
                isPaused: resultPaused[0],
                isWhitelist: whitelist,
                ...totalVndcCanRedeem,
                baseRate: new BigNumber(resultBaseRate?.toString()).toString(),
                pairAddress,
                ...resultReserves,
                totalSupply,
                lpBalance,
                balanceOfCurrency:resBalanceOfCurency
            
            }
        }
    } catch (error) {
        console.log("ábdnajsbdlaks", error)
        return { dataMint : {
            feeWithdraw: "",
            mintAmountMint: "",
            isPaused: false,
            isWhitelist: false,
            totalVndcCanRedeem: "0",
            lpPerUser: "0",
            baseRate: "0",
            pairAddress,
            reserve0: "0",
            reserve1: "0",
            totalSupply: 0,
            lpBalance:"0",
            balanceOfCurrency: 0,
            totalLp: "0"
        }
    }
    }
}


export const fetchHistoryData = async (skip: number, limit: number, method:string, chainId:number, walletAddress:string,  provider: Provider|null): Promise<{dataHistory:HistoryType}> => {
    if(walletAddress?.length){
        try {
           const par = method?.length ? {skip, limit, method, chainId, walletAddress} : {skip, limit, chainId, walletAddress}
            const res = (await axios.get("/histories", {params: par})).data
            const { orders, total } = res?.data
            const mergeArr:OrderItem[] = []
            for(let index=0; index< orders?.length; index++){
                // eslint-disable-next-line no-await-in-loop
                const dataTime = (await provider?.getBlock(orders[index]?.blockNumber)).timestamp
                mergeArr.push({...orders[index], timeStamp: dataTime})
            }

            return {
                dataHistory: {
                    orders : mergeArr,
                    totalItem: total
                }
            }
           
        } catch (error) {
            console.log(error)
            return {
                dataHistory: {
                    orders: null,
                    totalItem: 0
                }
            }
        }
    }
    return {
        dataHistory: {
            orders: null,
            totalItem: 0
        }
    }
}

export const getQuoterOut = async ( routerAddress: string, amountIn:string, reserve0: string, reserve1: string, chainId: number) => {
    try {
        const converAmountIn = new BigNumber(amountIn).multipliedBy(1E18).toString()
        const calls = [
            {
                address: routerAddress,
                name: 'quote',
                params: [converAmountIn, reserve1, reserve0 ]
            }
        ]
        
        const [result] = await multicall(routerAbi, calls, chainId)
        return new BigNumber(result?.amountB?.toString()).dividedBy(1E18).toString()
    } catch (error) {
        console.log(error)
        return ""
    }
}

export const getQuoterIn = async ( routerAddress: string, amountIn:string, reserve0: string, reserve1: string, chainId: number) => {
    try {
        const converAmountIn = new BigNumber(amountIn).multipliedBy(1E18).toString()
        const calls = [
            {
                address: routerAddress,
                name: 'quote',
                params: [converAmountIn, reserve0, reserve1]
            }
        ]
        
        const [result] = await multicall(routerAbi, calls, chainId)
        return new BigNumber(result?.amountB?.toString()).dividedBy(1E18).toString()
    } catch (error) {
        console.log(error)
        return ""
    }
}

export const getAmountOutRedeem = async( amountIn:string, totalSupply:string, pairAddress: string, account:string, chainId: number) => {
    try {
        const calls = [
            {
                address: pairAddress,
                name: 'balanceOf',
                params: [account]
            },
        ]
        const [result] = await multicall(lpAbi, calls, chainId)

        const balanceOf = new BigNumber(result?.toString())
        const amountA = new BigNumber(new BigNumber(amountIn).multipliedBy(1E18))

        const amountLiquidty = amountA.multipliedBy(new BigNumber(totalSupply).dividedBy(balanceOf))

        return amountLiquidty.dividedBy(1E18).toString()
    } catch (error) {
        console.log(error)
        return "0"
    }
}