import { Provider } from "@ethersproject/providers";
import { Button, Flex, Heading, useMatchBreakpoints } from "@pancakeswap/uikit";
import { Paginate } from "components/Paginate";
import { ITEMSPERPAGE } from "config";
import useTheme from "hooks/useTheme";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { GetDataActions, UseHistory } from "state/action";
import { GetDataHistory } from "state/history";
import { fetchActiveTab } from "state/history/actions";
import { ACTIVETAB } from "state/history/types";
import Table from "./Table";
import { ContainerHistory, CsButtonSecondary } from "./styles";

interface Props {
    account:string,
    chainId:number,
    provider: Provider|null
}
const History:React.FC<Props> = ({
    account,
    chainId,
    provider
}) => {
    const { isDesktop } = useMatchBreakpoints()
    const { theme } = useTheme()
    const dispatch = useDispatch<AppDispatch>();
    const history = GetDataHistory()
    const dataActions = GetDataActions()
    const { txHash, dataHistory } = dataActions
    const { orders, totalItem } = dataHistory
    const { activeTab } = history
    const [ activePage, setActivePage ] = useState(0)

    UseHistory(activePage*ITEMSPERPAGE, ITEMSPERPAGE, activeTab, chainId, account, txHash, provider)
    const handlePageClick = (event) => {
        setActivePage(event.selected)
    };

    return (
        <ContainerHistory>
            { isDesktop &&
                <Flex
                    width="100%"
                    height="auto"
                > 
                    <img src="/images/vndc/banner.png?versions=1.1" alt="banner-vndc" style={{width:"100%", height:"auto"}} />
                </Flex>
            }
            <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexDirection={["column",,"row"]}
                flexWrap="wrap"
                style={{gap:"1rem"}}
            >
                <Flex width={["100%",,"auto"]} style={{gap:"12px"}} alignItems="center" justifyContent="flex-start">
                    <Flex background={theme.colors.yellow} width="16px" height="40px" borderRadius="4px" />
                    <Heading scale="xl" color="#3D3D3D"  textTransform="uppercase">History</Heading>
                </Flex>
                <Flex style={{gap:"1rem"}} width={["100%",,"auto"]} justifyContent="space-between" mt={["2rem",,,"0rem"]}>
                    <CsButtonSecondary 
                        width={["30%",,"120px"]}
                        onClick={() => {dispatch(fetchActiveTab({activeTab:ACTIVETAB.ALL})); setActivePage(0)}}
                        variant={activeTab === ACTIVETAB.ALL ? "primary" : "secondary"}
                        isActive={activeTab === ACTIVETAB.ALL ? !false : false}
                    >
                        ALL
                    </CsButtonSecondary>
                    <CsButtonSecondary 
                        width={["30%",,"120px"]} 
                        variant={activeTab === ACTIVETAB.MINT ? "primary" : "secondary"}
                        isActive={activeTab === ACTIVETAB.MINT ? !false : false}

                        onClick={() =>  {dispatch(fetchActiveTab({activeTab:ACTIVETAB.MINT})); setActivePage(0)}}
                    >
                        MINT
                    </CsButtonSecondary>
                    <CsButtonSecondary 
                        width={["30%",,"120px"]}
                        isActive={activeTab === ACTIVETAB.REDEEM ? !false : false}
                        variant={activeTab === ACTIVETAB.REDEEM ? "primary" : "secondary"}
                        onClick={() => {dispatch(fetchActiveTab({activeTab:ACTIVETAB.REDEEM})); setActivePage(0)}}

                    >
                        REDEEM
                    </CsButtonSecondary>
                </Flex>
            </Flex>
            <Table account={account} orders={orders} chainId={chainId}/>
            <Flex>
                <Paginate
                    total={Math.ceil(totalItem / ITEMSPERPAGE)} 
                    pageSize={ITEMSPERPAGE} 
                    pageActive={activePage}
                    handlePageClick={handlePageClick}
                />
            </Flex>
        </ContainerHistory>
    )
}

export default History