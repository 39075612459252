import React, { useMemo } from "react";
import { Flex, Text } from "@pancakeswap/uikit";
import useTheme from "hooks/useTheme";

interface Props {
    account: string
}
const NodataOrNotConnectWallet: React.FC<Props> = ({
    account
}) => {
    const { theme } = useTheme()
    const renderSrc = useMemo(() => {
        if(account === null) {
            return "/images/vndc/not_connect_wallet.png"
        }
        return "/images/vndc/nodata.png"
    },[account])

    return (
        <Flex 
            background={theme.colors.backgroundAlt2} 
            width="100%" 
            height="200px" 
            flexDirection="column" 
            justifyContent="flex-end" 
            alignItems="center"
            paddingBottom="2rem"
        >
            <Flex width="100%" justifyContent="center" alignItems="center" mb="1.5rem">
                <img src={renderSrc} alt="logo" style={{width:"80px", height:"auto"}} />
            </Flex>
            { account === null ?
                <Flex alignItems="center" flexDirection={["column",,"row"]} style={{gap:'5px'}} >
                    <Text color="gold" bold>Connect wallet</Text>
                    <Text color="textSubtle">to view transaction history</Text>
                </Flex>
            :
                <Text color="textSubtle">Transaction history is empty </Text>
            }
        </Flex>
    )
}

export default NodataOrNotConnectWallet