import Container from "components/Layout/Container";
import PageFullWidth from "components/Layout/PageFullWidth";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import Actions from "./components/Actions";
import History from "./components/History";
import { CsFlex } from "./components/styles";

const Home = () => {
    const { account, chainId, provider } = useActiveWeb3React()
    return (
        <PageFullWidth>
            <Container>
                <CsFlex>
                    <History
                        account={account}
                        chainId={chainId}
                        provider={provider}
                    />
                    <Actions
                        account={account}
                        chainId={chainId}
                    />
                </CsFlex>
            </Container>
        </PageFullWidth>
    )
}

export default Home 